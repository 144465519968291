import React from "react";
import { Box, Button, Grid } from "@mui/material";
import FileSaver from "file-saver";
import * as XLSX from 'xlsx';
import { useCookies } from "react-cookie";
import { getRequestAPI } from "../utils";

/*
  * This component is used to export the working session in a csv file
*/
const ExportCSV = ({ExportForm}) => {
  // const [workingSessions, setWorkingSessions] = React.useState([]);
  const [cookies, setCookie, removeCookie] = useCookies();
  // ******** XLSX with object key as header *************
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  async function exportToCSV(){
    const workingSessions = await updateComponent();
    const ws = XLSX.utils.json_to_sheet(workingSessions);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    var filename = ((ExportForm.mission.id == "Tout")? "Working_Session": ExportForm.mission.title)+ fileExtension;
    FileSaver.saveAs(data, filename);
  };

  async function updateComponent() {
    var workingSessionPromise;
    if (ExportForm.mission.id === "Tout")
    {
        workingSessionPromise = await getRequestAPI("/manage/working-sessions/", cookies.auth_token);
    }
    else{
        workingSessionPromise = await getRequestAPI("/manage/missions/" + ExportForm.mission.id + "/working-sessions", cookies.auth_token);
    }
    var workingSessionsResponse = await workingSessionPromise.json();
    if (ExportForm.presta.id !== "Tout"){
      workingSessionsResponse = workingSessionsResponse.filter((elt)=> +elt.owner.id === +ExportForm.presta.id);
    }
    if (ExportForm.isSwitched){
      workingSessionsResponse = workingSessionsResponse.filter((elt) => new Date(elt.begin_datetime).getTime() >= ExportForm.begin_datetime.getTime() && new Date(elt.end_datetime).getTime() < ExportForm.end_datetime.getTime());
    }
    return adaptationOfObjectWorkingSession(workingSessionsResponse);
}

async function adaptationOfObjectWorkingSession(workingSessionObject)
{
    var newData = [];
    var tmpObject = {};
    for (const key1 in workingSessionObject) {
        if (workingSessionObject[key1]["state"] == 2)
        {
            tmpObject = {};
            var tempsDeTravail = await dateDiff(new Date(workingSessionObject[key1]["begin_datetime"]), new Date(workingSessionObject[key1]["end_datetime"]));
            tmpObject["Temps de travail"] = tempsDeTravail.totalhour;
            for (const key2 in workingSessionObject[key1])
            {
              if (key2 !="state")
              {
                if (key2 == "owner" || key2 == "related_mission")
                {
                  if (key2 == "owner")
                  {
                    tmpObject["first_name"] = workingSessionObject[key1][key2]["first_name"];
                    tmpObject["last_name"] = workingSessionObject[key1][key2]["last_name"];
                    tmpObject["id"] = workingSessionObject[key1][key2]["id"];
                  }
                  if (key2 == "related_mission")
                  {
                    const workingSessionPromise = await getRequestAPI("/manage/missions/"+ workingSessionObject[key1][key2], cookies.auth_token)
                    const workingSessionsResponse = await workingSessionPromise.json()
                    tmpObject[key2] =  workingSessionsResponse.title;
                    tmpObject["related_mission_id"] = workingSessionObject[key1][key2];
                  }
                }
                else{
                  tmpObject[key2] = workingSessionObject[key1][key2];
                }
              }
            }
            newData.push(tmpObject);
        }
    }
    return newData;
}

function dateDiff(date1, date2){
  var diff = {}                           // Initialisation du retour
  var tmp = date2 - date1;

  tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
  diff.sec = tmp % 60;                    // Extraction du nombre de secondes

  tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
  diff.min = tmp % 60;                    // Extraction du nombre de minutes

  tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
  diff.hour = tmp % 24;                   // Extraction du nombre d'heures
   
  tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
  diff.day = tmp;
  
  diff.totalhour = (diff.day * 24+diff.hour)+"h"+(diff.min)+"m";
  return diff;
}

const makeCsv = async (filename, rows) => {

  const separator = ';';
  const keys = Object.keys(rows[0]);

  const csvContent = `${keys.join(separator)}\n${
    rows.map((row) => keys.map((k) => {
      let cell = row[k] === null || row[k] === undefined ? '' : row[k];

      cell = cell instanceof Date
        ? cell.toLocaleString()
        : cell.toString().replace(/"/g, '""');
      if (cell.search(/("|,|\n)/g) >= 0) {
        cell = `"${cell}"`;
      }
      return cell;
    }).join(separator)).join('\n')}`;

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  // ******** XLSX with new header *************
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const  headers = [
  //   { label: 'Début', key: 'begin_datetime' },
  //   { label: 'Fin', key: 'end_datetime' },
  //   { label: 'Description', key: 'description' },
  //   { label: 'Nom', key: 'last_name'},
  //   { label: 'Prénom', key: 'first_name'},
  //   { label: 'Titre', key: 'title'},
  // ];
  // const Heading = [
  //   {
  //       begin_datetime: "Début",
  //       end_datetime: "Fin",
  //       description: "Description",
  //       last_name: "Nom",
  //       first_name: "Prénom",
  //       title: "Titre"
  //   }
  // ];

  // const exportToCSV = (csvData, fileName, wscols) => {
  //   const ws = XLSX.utils.json_to_sheet(Heading, {
  //     header: ["begin_datetime", "end_datetime", "description", "last_name", "first_name", "title"],
  //     skipHeader: true,
  //     origin: 0 //ok
  //   });
  //   ws["!cols"] = wscols;
  //   XLSX.utils.sheet_add_json(ws, csvData, {
  //     header: ["begin_datetime", "end_datetime", "description", "last_name", "first_name", "title"],
  //     skipHeader: true,
  //     origin: -1 //ok
  //   });
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };
  return (
  <Box>
    <Grid 
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      >
      <Grid item marginRight={2}>
        <Button
          // variant="warning"
          variant="contained"
          color="primary"
          onClick={e => exportToCSV()}
          >
            Exporter en xlsx
        </Button>
      </Grid>
      {/* <Grid item marginRight={2}>
        <Button
          // variant="warning"
          variant="contained"
          color="primary"
          onClick={e => exportToCSV()}
          >
            Exporter en CSV
        </Button>
      </Grid> */}
    </Grid>
{/* <CSVLink data={workingSessions} headers={headers} separator={","}>Download working sessions</CSVLink> */}
{/* <CSVDownload data={["aa"]} target="_blank" >aa</CSVDownload> */}
  </Box>
          
  );
};

export default ExportCSV;  


