import React from "react";
import { useCookies } from "react-cookie";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { UserContext } from "../contexts/user";
import AdminMissionCard from "../components/AdminMissionCard";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ButtonGroup } from "@mui/material";
import { getUserManagedMission } from "../requests/missions/manage/missions";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";

/*
  * This component render a page with all the missions that the user is managing
  * REMINDER: Admin get all the missions
*/
function AdminMissionPage() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [missionsList, setMissionsList] = React.useState([]);
  const [filteredMissionList, setFilteredMissionList] = React.useState([]);

  const [activeCloseFilter, setActiveCloseFilter] = React.useState(-1);

  const { user, setUser } = React.useContext(UserContext);

  const { status, isLoading, isFetching, error } = useQuery("getUserManagedMission", {
    queryFn: () => getUserManagedMission(cookies.auth_token),
    onSuccess: async (data) => {
      if (data.ok) {
        const content = await data.json();
        setMissionsList(content);
        setFilteredMissionList(content);
      }
    },
    enabled: user.id !== null && cookies.auth_token !== null,
    retry: 0,
  });

  function handleSearch(event) {
    setActiveCloseFilter(-1);

    if (event.currentTarget.value === "") {
      setFilteredMissionList(missionsList);
    } else {
      setFilteredMissionList(
        missionsList.filter((elt) =>
          elt.title
            .toLowerCase()
            .includes(event.currentTarget.value.toLowerCase())
        )
      );
    }
  }

  function handleCloseFilter(event, val) {
    if (activeCloseFilter !== val) {
      setFilteredMissionList(missionsList.filter((elt) => elt.closed == val));
      setActiveCloseFilter(val);
    } else {
      setFilteredMissionList(missionsList);
      setActiveCloseFilter(-1);
    }
  }

  return (
    <Box
      sx={{
        width: "800px",
        margin: "auto",
      }}
    >
      <div>
        <Stack spacing={1}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h1>Toutes les missions</h1>
            </Grid>
            <Grid item>
              <FormControl variant="outlined">
                <TextField
                  id="search-mission-bar"
                  type="search"
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  variant={activeCloseFilter === 1 ? "contained" : "outlined"}
                  onClick={(event) => handleCloseFilter(event, 1)}
                >
                  <LockIcon color="error" />
                </Button>
                <Button
                  variant={activeCloseFilter === 0 ? "contained" : "outlined"}
                  onClick={(event) => handleCloseFilter(event, 0)}
                >
                  <LockOpenIcon color="success" />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          {isLoading || isFetching ? (
            <div>
              <center>
                <CircularProgress />
              </center>
            </div>
          ) : filteredMissionList.length == 0 ? (
            <center>
              <Divider>
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "#838383" }}
                >
                  Aucune mission à afficher
                </Typography>
              </Divider>
            </center>
          ) : (
            <div>
              <Stack spacing={2}>
                <Divider />
                {filteredMissionList.map((entry) => {
                  return (
                    <AdminMissionCard
                      key={entry.id}
                      data={entry}
                      see_details={true}
                    />
                  );
                })}
              </Stack>
            </div>
          )}
        </Stack>
      </div>
    </Box>
  );
}

export default AdminMissionPage;
