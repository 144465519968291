import { AppConfig } from "../../AppConfig";

export async function getStudentWorkingSessionOnMission(missionId, token) {
    const response = await fetch(AppConfig.api_ip + `/missions/${missionId}/working-sessions/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    });
    return response.json();
}