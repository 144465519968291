import React from "react";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";

import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import WorkingSessionFormModal from "./WorkingSessionFormModal";
import { AddWorkingSessionFormConfig } from "../AppConfig";

/*
  * This component is used to add a working session to a mission.
  * It is used in MissionDetail.js
  * It is a modal that opens when the user clicks on the "AJOUTER" button.
*/
function AddWorkingSessionModal(props) {
  const [open, setOpen] = React.useState(false);
  const workingSessionInit = {
    title: "",
    description: "",
    begin_datetime: new Date(),
    end_datetime: new Date(),
    related_mission: props.missionId,
  };

  return (
    <div>
      <WorkingSessionFormModal config={AddWorkingSessionFormConfig} type="post" workingSession={workingSessionInit} isModalOpen={open} missionId={props.missionId} setIsModalOpen={setOpen}/>
      <Grid container>
        <Grid item xs={8}>
          <Button disabled={props.closed === true} variant="contained" color="success" onClick={() => setOpen(true)} startIcon={<AddIcon />}>
            AJOUTER
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled variant="contained" color="secondary" startIcon={<MotionPhotosAutoIcon />}>
            AUTO
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default AddWorkingSessionModal;