import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { UserContext } from "../contexts/user";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import AddWorkingSessionModal from "../components/AddWorkingSessionModal";
import MissionCard from "../components/MissionCard";
import WorkingSessionTable from "../components/WorkingSessionTable";
import { ButtonGroup, Button } from "@mui/material";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
import LockIcon from "@mui/icons-material/Lock";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { getRequestAPI } from "../utils";
import { RemoveCircleOutline } from "@mui/icons-material";
import { getStudentWorkingSessionOnMission } from "../requests/working-sessions/working-session";
import { useQuery } from "react-query";
import { getStudentMissionDetail } from "../requests/missions/missions";

const initMission = {
  mission: {
    owners: [],
  },
};

/*
    * This component is used to display the details of a mission with all the working sessions on student side
    * Mission id is injected by the router in the url
*/
function MissionDetail() {
  const [cookies, setCookie, removeCookie] = useCookies();

  const { missionId } = useParams();
  const [open, setOpen] = useState(false);
  const handleFilterIconClick = () => {
    setOpen(!open);
  };

  const [missionDetail, setMissionDetail] = React.useState(initMission);

  const [workingSessions, setWorkingSessions] = React.useState([]);
  const [injectedWorkingSessions, setInjectedWorkingSessions] = React.useState(
    []
  );
  const [activeFilter, setActiveFilter] = React.useState(-1);

  const { user, setUser } = React.useContext(UserContext);

  const {
    status: workingSessionStatus,
    isLoading: workingSessionIsLoading,
    isFetching: workingSessionIsFetching,
    error: workingSessionError,
  } = useQuery("getStudentWorkingSessionOnMission", {
    queryFn: () =>
      getStudentWorkingSessionOnMission(missionId, cookies.auth_token),
    onSuccess: async (data) => {
      if (data.ok) {
        const content = await data.json();
        let workingSessions = content.sort((a, b) => b.id - a.id);
        setWorkingSessions(workingSessions);
        setInjectedWorkingSessions(workingSessions);
      }
    },
    enabled: missionId !== undefined && cookies.auth_token !== null,
    retry: 0,
  });

  const {
    status: missionDetailStatus,
    isLoading: missionDetailIsLoading,
    isFetching: missionDetailIsFetching,
    error: missionDetailError,
  } = useQuery("getStudentMissionDetail", {
    queryFn: () =>
      getStudentMissionDetail(user.id, missionId, cookies.auth_token),
    onSuccess: async (data) => {
      if (data.ok) {
        const content = await data.json();
        setMissionDetail(content);
      }
    },
    enabled:
      missionId !== undefined &&
      user.id !== undefined &&
      cookies.auth_token !== null,
    retry: 0,
  });

  React.useEffect(() => {
    async function updateComponent() {
      const missionPromise = await getRequestAPI(
        "/students/" + user.id + "/missions/" + missionId,
        cookies.auth_token
      );
      const missionResponse = await missionPromise.json();

      const workingSessionPromise = await getRequestAPI(
        "/students/" + user.id + "/working-sessions",
        cookies.auth_token
      );
      const workingSessionsResponse = await workingSessionPromise.json();

      setMissionDetail(missionResponse);

      if (workingSessionPromise.status === 200) {
        const filterWorkingSession = workingSessionsResponse
          .filter(
            (workingSession) => workingSession.related_mission == missionId
          )
          .sort((a, b) => b.id - a.id);

        setWorkingSessions(filterWorkingSession);
        setInjectedWorkingSessions(filterWorkingSession);
      }
    }

    updateComponent();
  }, []);

  function filterWorkingSession(event, state) {
    if (activeFilter !== state) {
      setInjectedWorkingSessions(
        workingSessions.filter((elt) => elt.state === state)
      );
      setActiveFilter(state);
    } else {
      setInjectedWorkingSessions(workingSessions);
      setActiveFilter(-1);
    }
  }

  return (
    <Box
      style={{
        width: "800px",
        margin: "auto",
      }}
    >
      {workingSessionIsLoading || workingSessionIsFetching ? (
        <div>
          <center>
            <CircularProgress />
          </center>
        </div>
      ) : (
        <Stack spacing={2}>
          <div>
            <h1>Detail d'une mission</h1>
            <MissionCard data={missionDetail} see_details={false} />
          </div>
          <div>
            <Grid container justifyContent="space-between" alignItems="default">
              <AddWorkingSessionModal
                closed={missionDetail.mission.closed}
                missionId={Number(missionId)}
              />
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <IconButton
                  style={{ marginLeft: "auto" }}
                  color="inherit"
                  aria-label="filterButton"
                  onClick={handleFilterIconClick}
                ></IconButton>
                <Tooltip title="En attente">
                  <Button
                    variant={activeFilter === 0 ? "contained" : "outlined"}
                    onClick={(e) => filterWorkingSession(e, 0)}
                  >
                    <AddTaskIcon color="primary" />
                  </Button>
                </Tooltip>
                <Tooltip title="En cours de validation">
                  <Button
                    variant={activeFilter === 1 ? "contained" : "outlined"}
                    onClick={(e) => filterWorkingSession(e, 1)}
                  >
                    <HourglassBottomOutlinedIcon color="warning" />
                  </Button>
                </Tooltip>
                <Tooltip title="Refusée">
                  <Button
                    variant={activeFilter === 3 ? "contained" : "outlined"}
                    onClick={(e) => filterWorkingSession(e, 3)}
                  >
                    <RemoveCircleOutline color="error" />
                  </Button>
                </Tooltip>
                <Tooltip title="Validée">
                  <Button
                    variant={activeFilter === 2 ? "contained" : "outlined"}
                    onClick={(e) => filterWorkingSession(e, 2)}
                  >
                    <LockIcon color="success" />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Grid>
          </div>
          <div>
            <WorkingSessionTable data={injectedWorkingSessions} />
          </div>
        </Stack>
      )}
    </Box>
  );
}

export default MissionDetail;
