import React from "react";
import AddTaskIcon from '@mui/icons-material/AddTask';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { IconButton } from '@mui/material';
import { Tooltip } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

/*
    * This component is use to display an icon representing the state of a working session
    * REMINDER : state 0 = draft, 1 = submitted, 2 = validated, 3 = refused
*/
function WorkingSessionStateIcon(props) {
    const iconList = [
        { icon: <AddTaskIcon color="primary" />, description: "En attente" },
        { icon: <HourglassBottomOutlinedIcon color="warning" />, description: "En cours de validation" },
        { icon: <LockIcon color="success" />, description: "Validée" },
        { icon: <RemoveCircleIcon color="error"/>, description: "Refusée" },
    ]

    return (
        <Tooltip title={iconList[props.state].description}>
            <IconButton style={{ backgroundColor: "transparent" }} >
                {iconList[props.state].icon}
            </IconButton>
        </Tooltip>
    )
}

export default WorkingSessionStateIcon;