import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField';
import AdminWorkingSessionTable from "../components/AdminWorkingSessionTable";
import AdminAddWorkingSessionModal from "../components/AdminAddWorkingSessionModal";
import { ButtonGroup, Button } from "@mui/material";
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { getRequestAPI } from "../utils";
import AdminMissionCard from "../components/AdminMissionCard";
import Autocomplete from '@mui/material/Autocomplete';
import { RemoveCircleOutline } from "@mui/icons-material";
import { Tooltip } from "@material-ui/core";

const initMission = {
    owners: [],
    workers: []
}

/*
    * This component is used to display the details of a mission with all the working sessions on admin side
    * Mission id is injected by the router in the url
*/
function AdminMissionDetail() {
    const [cookies] = useCookies();

    const { missionId } = useParams();

    const [missionDetail, setMissionDetail] = React.useState(initMission);

    const [workingSessions, setWorkingSessions] = React.useState([]);
    const [injectedWorkingSessions, setInjectedWorkingSessions] = React.useState([]);
    const [activeFilter, setActiveFilter] = React.useState(-1)
    const [workerFilter, setWorkerFilter] = React.useState("")

    React.useEffect(
        () => {
            async function updateComponent() {
                const missionPromise = await getRequestAPI("/manage/missions/" + missionId, cookies.auth_token)
                const missionResponse = await missionPromise.json();

                const workingSessionPromise = await getRequestAPI("/manage/missions/" + missionId + "/working-sessions", cookies.auth_token)
                const workingSessionsResponse = await workingSessionPromise.json()

                setMissionDetail(missionResponse);

                const filterWorkingSession = workingSessionsResponse.filter((workingSession) => workingSession.state !== 0).sort((a, b) => b.id - a.id);

                setWorkingSessions(filterWorkingSession);
                setInjectedWorkingSessions(filterWorkingSession);
            }

            updateComponent()
        }, []
    )

    function formatDateFromString(dateString) {
        return new Intl.DateTimeFormat('en-US').format(Date.parse(dateString))
    }

    function filterWorkingSession(event, state, worker) {
        if (worker === "") {
            if (activeFilter !== state) {
                setInjectedWorkingSessions(workingSessions.filter((elt) => elt.state === state))
                setActiveFilter(state)
            }
            else {
                setInjectedWorkingSessions(workingSessions)
                setActiveFilter(-1)
            }
        }
        else {
            if (activeFilter !== state) {
                setInjectedWorkingSessions(workingSessions.filter((elt) => elt.state === state && elt.owner.last_name === worker.substring(worker.indexOf(' ') + 1)))
                setActiveFilter(state)
            }
            else {
                setInjectedWorkingSessions(workingSessions.filter((elt) => elt.owner.last_name === worker.substring(worker.indexOf(' ') + 1)))
                setActiveFilter(-1)
            }
        }
    }

    function filterWorkingPeople(event, worker, state) {
        if (state === -1) {
            if (workerFilter !== worker && worker != null) {
                setInjectedWorkingSessions(workingSessions.filter((elt) => elt.owner.last_name === worker.substring(worker.indexOf(' ') + 1)))
                setWorkerFilter(worker)
            }
            else {
                setInjectedWorkingSessions(workingSessions)
                setWorkerFilter("")
            }
        }
        else {
            if (workerFilter !== worker && worker != null) {
                setInjectedWorkingSessions(workingSessions.filter((elt) => elt.state === state && elt.owner.last_name === worker.substring(worker.indexOf(' ') + 1)))
                setWorkerFilter(worker)
            }
            else {
                setInjectedWorkingSessions(workingSessions.filter((elt) => elt.state === state))
                setWorkerFilter("")
            }
        }
    }

    const [worker, setWorker] = React.useState('');

    var results = [];
    workingSessions.forEach((presta) => {
        results.push(new Array({ value: presta.owner.first_name + " " + presta.owner.last_name },
            { label: presta.owner.first_name + " " + presta.owner.last_name }))
    }
    );

    const uniqueArray = results.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === results.findIndex(obj => {
            return JSON.stringify(obj) === _value;
        });
    });

    return (
        <Box style={{
            width: "800px",
            margin: "auto",
        }}>
            <Stack spacing={2}>
                <div>
                    <h1>Detail d'une mission</h1>
                    <AdminMissionCard data={missionDetail} see_details={false} />
                </div>
                <div>
                    <Grid container justifyContent="space-between" alignItems="default">
                        <AdminAddWorkingSessionModal closed={missionDetail.closed} missionId={Number(missionId)} workers={missionDetail.workers} />
                        <Autocomplete
                            clearOnEscape
                            freeSolo
                            id="auto-workers"
                            options={uniqueArray.map((option) => option[0].value)}
                            onChange={(event, newValue) => { setWorker(newValue); filterWorkingPeople(event, newValue, activeFilter) }}
                            sx={{ width: 250 }}
                            renderInput={(params) => <TextField {...params} label="Prestataires" size="small" onClick={(event, newValue) => setWorker(newValue)} />}
                        />
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Tooltip title="En cours de validation">
                                <Button variant={(activeFilter === 1 ? "contained" : "outlined")} onClick={(e) => filterWorkingSession(e, 1, workerFilter)}>
                                    <HourglassBottomOutlinedIcon color="warning" />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Refusée">
                                <Button variant={(activeFilter === 3 ? "contained" : "outlined")} onClick={(e) => filterWorkingSession(e, 3, workerFilter)}>
                                    <RemoveCircleOutline color="error" />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Validée">
                                <Button variant={(activeFilter === 2 ? "contained" : "outlined")} onClick={(e) => filterWorkingSession(e, 2, workerFilter)}>
                                    <LockIcon color="success" />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </div>
                <div>
                    <AdminWorkingSessionTable data={injectedWorkingSessions} />
                </div>
            </Stack>
        </Box>
    )
}

export default AdminMissionDetail;