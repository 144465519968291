import { AppConfig } from "../../AppConfig";

export async function getStudentMissionList(userId, token) {
    const response = await fetch(AppConfig.api_ip + `/students/${userId}/missions/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    });
    return response;
}

export async function getStudentMissionDetail(userId, missionId, token) {
    const response = await fetch(AppConfig.api_ip + `/students/${userId}/missions/${missionId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    });
    return response;
}