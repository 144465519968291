import React from "react";
import { Alert, Snackbar } from "@mui/material";

/*
    * This component is use to display a success message on the top right of the screen for 2 seconds
    * https://mui.com/components/snackbars/
*/
function MessageSnackBar(props) {

    function handleSnackClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }

        props.toogleSnackBar(false);
    };

    return (
        <Snackbar open={props.open} autoHideDuration={2000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

export default MessageSnackBar;