import { AppConfig } from "../../AppConfig";

export const validateAccessToken = async (token) => {
    const response = await fetch(
      AppConfig.api_ip + "/token/verify/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token }),
      }
    );

    return response.json();
  };
