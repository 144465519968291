import React from 'react';
import { Button } from '@mui/material';
import { Dialog } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { DialogActions } from '@mui/material';

/*
    * This component is a confirmation dialog to be sure that the user wants to do an action
*/
function ConfirmationDialog(props) {
    function handleAgree(event) {
        event.preventDefault();
        props.toogleDialog(false);
        props.callback();
    }

    function handleClose(event) {
        event.preventDefault();
        props.toogleDialog(false);
    }

    return (<Dialog
        open={props.dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.description}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button color="error" onClick={handleClose}>Annuler</Button>
            <Button color="success" onClick={handleAgree} autoFocus>
                Confirmer
            </Button>
        </DialogActions>
    </Dialog>)
}

export default ConfirmationDialog;