import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CardActions from "@mui/material/CardActions";
import { useNavigate } from 'react-router-dom';
import { Container } from "@mui/system";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { AvatarGroup, Avatar, Tooltip } from "@mui/material";
import { Stack } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getCRIPhotoUrl } from "../utils";

/*
    * This component is used to display a mission card in the admin mission page
    * It is used in the AdminMissionList component
*/
function AdminMissionCard(props) {
    const navigate = useNavigate();
    if (props.data.workers !== undefined) {
        return (
            <Card sx={{ minWidth: 150, backgroundImage: "url(https://sandra.pascal-geay.fr/wp-content/uploads/2019/03/work.jpeg)", backgroundSize: "cover", backgroundPosition: "center" }} color="text.secondary" key={props.data.id} >
                <Container sx={{ background: "rgba(0,0,0,0.4)", color: "white" }}>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        <Grid item xs={1}>
                            <CardContent key={props.data.id + " content"}>
                                {props.data.closed ? <LockIcon color="error" fontSize="large" /> : <LockOpenIcon fontSize="large" color="success" />}
                            </CardContent>
                        </Grid>
                        <Grid item xs={(props.see_details === true) ? 9 : 11}>
                            <CardContent key={props.data.id + " content"}>
                                <Stack spacing={1}>
                                    <Typography variant="h6" key={props.data.id + " title"} sx={{ fontWeight: "bold", textTransform: 'uppercase' }}>
                                        {props.data.title}
                                    </Typography>
                                    <Typography style={{
                                        display: 'flex',
                                        alignItems: 'stretch',
                                    }}>
                                        <Tooltip title="Vos dates de travail sur cette mission"><AccessTimeIcon sx={{ marginRight: '10px' }} /></Tooltip>
                                        du {props.data.start_date} au {props.data.end_date}
                                    </Typography>
                                    <Typography key={props.data.id + " description"}>
                                        {props.data.description}
                                    </Typography>
                                    <Grid container justifyContent="space-between">
                                        <AvatarGroup max={5} total={props.data.owners.length} sx={{ justifyContent: 'left' }}>
                                            {props.data.owners.map((owner) => (
                                                <Tooltip title={owner.last_name + " " + owner.first_name} key={owner.id}>
                                                    <Avatar alt={owner.last_name + " " + owner.first_name} src={getCRIPhotoUrl(owner.last_name, owner.first_name)} />
                                                </Tooltip>
                                            ))}
                                        </AvatarGroup>
                                        <AvatarGroup max={6} total={props.data.workers.length} sx={{ justifyContent: 'right' }}>
                                            {props.data.workers.map((worker) => (
                                                <Tooltip title={worker.student.last_name + " " + worker.student.first_name} key={worker.student.id}>
                                                    <Avatar alt={worker.student.last_name + " " + worker.student.first_name} src={getCRIPhotoUrl(worker.student.last_name, worker.student.first_name)} />
                                                </Tooltip>
                                            ))}
                                        </AvatarGroup>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Grid>
                        {(props.see_details == true) ?
                            <Grid item xs={2}>
                                <CardActions key={props.data.id + " action"}>
                                    <Button variant="contained" className="form__custom-button" onClick=
                                        {(e) => navigate("/admin/missions/" + props.data.id)}
                                    >Voir</Button>
                                </CardActions>
                            </Grid> :
                            <div></div>
                        }
                    </Grid>
                </Container>
            </Card >
        )
    }
    else {
        return (
            <Card sx={{ minWidth: 150, backgroundImage: "url(https://sandra.pascal-geay.fr/wp-content/uploads/2019/03/work.jpeg)", backgroundSize: "cover", backgroundPosition: "center" }} color="text.secondary" key={props.data.id} >
                <Container sx={{ background: "rgba(0,0,0,0.4)", color: "white" }}>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        <Grid item xs={1}>
                            <CardContent key={props.data.id + " content"}>
                                {props.data.closed ? <LockIcon color="error" fontSize="large" /> : <LockOpenIcon fontSize="large" color="success" />}
                            </CardContent>
                        </Grid>
                        <Grid item xs={(props.see_details === true) ? 9 : 11}>
                            <CardContent key={props.data.id + " content"}>
                                <Stack spacing={1}>
                                    <Typography variant="h6" key={props.data.id + " title"} sx={{ fontWeight: "bold", textTransform: 'uppercase' }}>
                                        {props.data.title}
                                    </Typography>
                                    <Typography style={{
                                        display: 'flex',
                                        alignItems: 'stretch',
                                    }}>
                                        <Tooltip title="Vos dates de travail sur cette mission"><AccessTimeIcon sx={{ marginRight: '10px' }} /></Tooltip>
                                        du {props.data.start_date} au {props.data.end_date}
                                    </Typography>
                                    <Typography key={props.data.id + " description"}>
                                        {props.data.description}
                                    </Typography>
                                    <Grid container justifyContent="space-between">
                                        <AvatarGroup max={5} total={props.data.owners.length} sx={{ justifyContent: 'left' }}>
                                            {props.data.owners.map((owner) => (
                                                <Tooltip title={owner.last_name + " " + owner.first_name} key={owner.id}>
                                                    <Avatar alt={owner.last_name + " " + owner.first_name} src={getCRIPhotoUrl(owner.last_name, owner.first_name)} />
                                                </Tooltip>
                                            ))}
                                        </AvatarGroup>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Grid>
                        {(props.see_details == true) ?
                            <Grid item xs={2}>
                                <CardActions key={props.data.id + " action"}>
                                    <Button variant="contained" className="form__custom-button" onClick=
                                        {(e) => navigate("/admin/missions/" + props.data.id)}
                                    >Voir</Button>
                                </CardActions>
                            </Grid> :
                            <div></div>
                        }
                    </Grid>
                </Container>
            </Card >
        )
    }
}

export default AdminMissionCard