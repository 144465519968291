import { AppConfig } from "../../../AppConfig";

export const getUserManagedMission = async (token) => {
    const response = await fetch(
      "https://intranet-pae-preprod.narval.cristal.je/v1/manage/missions/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    return response;
}