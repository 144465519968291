import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WorkingSessionStateIcon from './WorkingSessionStateIcon';
import { IconButton, Tooltip, Typography, } from '@mui/material';
import React from 'react';
import { Collapse } from '@mui/material';
import { TablePagination } from '@mui/material';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import WorkingSessionOption from './WorkingSessionOption';
import CloseIcon from '@mui/icons-material/Close';

/* 
    * This component is used to render a line into the table of working sessions on user side.
    * It is directly used by the above components.
*/
function WorkingSessionLine(props) {
    const titleMaxLength = 42;

    const [open, setOpen] = React.useState(false);

    function formatDatetime(datetime) {
        const formatted = new Date(datetime)
        return formatted.toLocaleString();
    }

    function hoursBetween(begin, end) {
        const a = new Date(begin);
        const b = new Date(end);

        const diff = b - a;
        const hours = Math.floor((b - a) / 3600000)

        const minutes = Math.floor((diff - hours * 3600000) / 60000);

        return hours + "h" + minutes
    }

    return (
        <React.Fragment>
            <TableRow key={props.workingSession.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {props.workingSession.title.length > titleMaxLength ? props.workingSession.title.substring(0, titleMaxLength) + "..." : props.workingSession.title}
                </TableCell>
                <TableCell align="center">{hoursBetween(props.workingSession.begin_datetime, props.workingSession.end_datetime)}</TableCell>
                <TableCell align="center"><WorkingSessionStateIcon workingSessionId={props.workingSession.id} state={props.workingSession.state} /></TableCell>
                <TableCell sx={{ width: "5px" }} align="center">
                    <WorkingSessionOption workingSession={props.workingSession} detailsOpen={open} detailsSet={setOpen} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack spacing={2}>
                            <Typography component="h6" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <strong>#{props.workingSession.id} - {props.workingSession.title}</strong>
                                <Tooltip title="Fermer">
                                    <IconButton size="small" color="error" onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                            <Typography component="subtitle1">
                                Par <b>{props.workingSession.owner.first_name + " " + props.workingSession.owner.last_name}</b>,<br/><br/>
                                {props.workingSession.description}
                            </Typography>
                            <Grid container justifyContent="space-between" alignItems="default">
                                <Typography component="subtitle1"><strong>Commencé le </strong>{formatDatetime(props.workingSession.begin_datetime)}</Typography>
                                <Typography component="subtitle1"><strong>Fini le </strong>{formatDatetime(props.workingSession.end_datetime)}</Typography>
                            </Grid>
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

/*
    * This component is used to render the table of working sessions on user side.
    * It is directly used by the above components.
*/
function WorkingSessionTable(props) {
    const rowsPerPage = 5;
    const [page, setPage] = React.useState(0)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell><b>TITRE</b></TableCell>
                        <TableCell align="center"><b>DUR&Eacute;E</b></TableCell>
                        <TableCell align="center"><b>&Eacute;TAT</b></TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((workingSession) => (
                        <WorkingSessionLine key={workingSession.id} workingSession={workingSession} />
                    ))}
                </TableBody>

            </Table>
            <TablePagination
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                page={page}
                onPageChange={handleChangePage}
            />
        </TableContainer >
    )
}

export default WorkingSessionTable;