import React from "react";
import jwt_decode from "jwt-decode";
import Layout from "./Layout";
import { useCookies } from "react-cookie";
import { UserContext } from "./contexts/user";
import { useQuery } from "react-query";
import { validateAccessToken } from "./requests/token/verify";
import { whoAmI } from "./requests/user/whoami";
import { getUserManagedMission } from "./requests/missions/manage/missions.js";

import "./App.css";
import "./style.min.css";

/*
  * This component is the main component of the app
  * It is used to build the user context
  * It is used to verify the access token
*/
function App() {
  const [cookies] = useCookies();
  const [user, setUser] = React.useState({});

  // Verify token before loading user into context
  const {
    status: verifyTokenStatus,
    isLoading: verifyTokenLoading,
    error: verifyTokenError,
  } = useQuery("validateAccessToken", {
    queryFn: () => validateAccessToken(cookies.auth_token),
    enabled: cookies.auth_token != undefined,
    retry: 0,
  });

  // Get user info to build user context
  const {
    status: userStatus,
    isLoading: userIsLoading,
    error: userError,
  } = useQuery("getUserInfo", {
    queryFn: () => whoAmI(cookies.auth_token),
    onSuccess: (data) => {
      const userInfo = jwt_decode(cookies.auth_token);

      const user1 = {
        id: userInfo.user_id,
        first_name: data.first_name.toLowerCase(),
        last_name: data.last_name.toLowerCase(),
      };

      setUser({ ...user, ...user1 });
    },
    enabled: verifyTokenStatus === "success" && cookies.auth_token != undefined && user.responsible_for != undefined,
    retry: 0,
  });

  // Retrieve user managed missions list
  const {
    status: missionStatus,
    isLoading: missionLoading,
    error: missionError,
  } = useQuery("getUerMission", {
    queryFn: () => getUserManagedMission(cookies.auth_token),
    onSuccess: async (data) => {
      let responsible_for = [];
      if (data.ok) {
        const content = await data.json();
        content.map((mission) => responsible_for.push(mission.id));
      }
      setUser({ ...user, responsible_for: responsible_for });
    },
    enabled: verifyTokenStatus === "success" && cookies.auth_token != undefined,
    retry: 0,
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {verifyTokenLoading || userIsLoading || missionLoading ? (
        <div>
          <svg
            className="tea lds-ripple"
            width="37"
            height="48"
            viewBox="0 0 37 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.0819 17H3.02508C1.91076 17 1.01376 17.9059 1.0485 19.0197C1.15761 22.5177 1.49703 29.7374 2.5 34C4.07125 40.6778 7.18553 44.8868 8.44856 46.3845C8.79051 46.79 9.29799 47 9.82843 47H20.0218C20.639 47 21.2193 46.7159 21.5659 46.2052C22.6765 44.5687 25.2312 40.4282 27.5 34C28.9757 29.8188 29.084 22.4043 29.0441 18.9156C29.0319 17.8436 28.1539 17 27.0819 17Z"
              stroke="#1e88e5"
              strokeWidth="2"
            ></path>
            <path
              d="M29 23.5C29 23.5 34.5 20.5 35.5 25.4999C36.0986 28.4926 34.2033 31.5383 32 32.8713C29.4555 34.4108 28 34 28 34"
              stroke="#1e88e5"
              strokeWidth="2"
            ></path>
            <path
              id="teabag"
              fill="#1e88e5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 25V17H14V25H12C10.3431 25 9 26.3431 9 28V34C9 35.6569 10.3431 37 12 37H18C19.6569 37 21 35.6569 21 34V28C21 26.3431 19.6569 25 18 25H16ZM11 28C11 27.4477 11.4477 27 12 27H18C18.5523 27 19 27.4477 19 28V34C19 34.5523 18.5523 35 18 35H12C11.4477 35 11 34.5523 11 34V28Z"
            ></path>
            <path
              id="steamL"
              d="M17 1C17 1 17 4.5 14 6.5C11 8.5 11 12 11 12"
              strokeridth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#1e88e5"
            ></path>
            <path
              id="steamR"
              d="M21 6C21 6 21 8.22727 19 9.5C17 10.7727 17 13 17 13"
              stroke="#1e88e5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      ) : (
        <Layout />
      )}
    </UserContext.Provider>
  );
}

export default App;
