import React from "react";

/*
    * This state follow the user that is logged in
    * responsible_for is an array of id of the working sessions that the user is responsible for
*/
const user = {
    id: 0,
    first_name: "",
    last_name: "",
    responsible_for: [],
}

export const UserContext = React.createContext({user, setUser: () => {}});