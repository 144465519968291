import { AppConfig } from "../../AppConfig";

export const whoAmI = async (token) => {
    const response = await fetch(
      AppConfig.api_ip + "/identity/whoami/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      }
    );

    return response.json();
  };