import React from "react";
import { useCookies } from "react-cookie";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Autocomplete, FormControl, FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { getRequestAPI } from "../utils";
import ExportCSV from "../components/ExportXlsx";
import { fr } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grow from '@mui/material/Grow';


const initAutoPresta = { id: "Tout", key: "Tout", title: "Tous les prestataires" };
const initMission = { id: "Tout", key: "Tout", title: "Toutes les missions" };

const ExportInit = {
    mission: initMission,
    presta: initAutoPresta,
    begin_datetime: new Date(),
    end_datetime: new Date(),
    isSwitched: false,
};

/*
    * This component render a form to export a csv file of working sessions
*/
function AdminMissionExport() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [missionsList, setMissionsList] = React.useState([]);
    const [studentsList, setStudentsList] = React.useState([]);
    const [ExportForm, setExportForm] = React.useState(ExportInit);
    const [switchDate, setSwitchDate] = React.useState(false);
    const [autoCompleteValue, setAutoCompleteValue] = React.useState(initMission);
    const [autoCompletePrestaValue, setAutoCompletePrestaValue] = React.useState(initAutoPresta);

    React.useEffect(
        () => {
            async function setMissionsListFromAPI() {
                const missionsFromApi = await getRequestAPI("/manage/missions/", cookies.auth_token);
                const studentsFromApi = await getRequestAPI("/students/", cookies.auth_token);
                if (missionsFromApi.ok === true) {
                    const data = await missionsFromApi.json();
                    data.forEach(element => {
                        element.key = element.id;
                        element.title = element.title + " (id: " + element.id + ")";
                    })

                    data.push(ExportForm.mission);
                    setMissionsList(data);
                }
                var studs = [];
                if (studentsFromApi.ok === true) {
                    studs = await studentsFromApi.json();
                    studs.forEach(element => {
                        element.key = element.id;
                        element.title = element.email + " (id: " + element.id + ")";
                    })
                }
                studs.push(initAutoPresta);
                setStudentsList(studs);
            }
            setMissionsListFromAPI()
        }, []
    )

    async function handleAutoCompleteChange(newvalue) {
        setAutoCompleteValue(newvalue);
        var tmpForm = ExportForm;
        tmpForm.mission = newvalue;
        setExportForm(tmpForm);
        var newPrestaList;
        if (newvalue.id === "Tout") {

            newPrestaList = await getRequestAPI("/students/", cookies.auth_token);
            var studs = [];
            if (newPrestaList.ok === true) {
                studs = await newPrestaList.json();
                studs.forEach(element => {
                    element.key = element.id;
                    element.title = element.email + " (id: " + element.id + ")";
                })
            }
            studs.push(initAutoPresta);
            setStudentsList(studs);
        }
        else {
            const newPrestaList = await getRequestAPI("/manage/missions/" + newvalue.id + "/workers/", cookies.auth_token);
            const studs = await newPrestaList.json();
            if (newPrestaList.ok === true) {
                studs.forEach(element => {
                    element.key = element.id;
                    element.title = element.email + " (id: " + element.id + ")";
                })
                studs.push(initAutoPresta);
                setStudentsList(studs);
            }
        }
        handleAutoCompletePrestaChange(initAutoPresta);
    }

    async function handleAutoCompletePrestaChange(newvalue) {
        setAutoCompletePrestaValue(newvalue);
        var tmpForm = ExportForm;
        tmpForm.presta = newvalue;
        setExportForm(tmpForm);
    }

    async function handleDatetimePickerChange(date, field) {
        setExportForm({
            ...ExportForm,
            [field]: date,
        })
    }

    async function handleSwitchChange(newvalue) {
        setSwitchDate(newvalue);
        setExportForm({
            ...ExportForm,
            isSwitched: newvalue,
        });
    }

    // const  headers = [
    //     { label: 'Début', key: 'begin_datetime' },
    //     { label: 'Fin', key: 'end_datetime' },
    //     { label: 'Description', key: 'description' },
    //     { label: 'Nom', key: 'last_name'},
    //     { label: 'Prénom', key: 'first_name'},
    //     { label: 'Titre', key: 'title'},
    //     { label: 'Titre', key: 'title'},
    //     { label: 'Titre', key: 'title'},
    // ];

    return (
        <Box style={{
            width: "800px",
            margin: "auto",
            marginTop: 20,
            border: '1px dashed grey'
        }} >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                marginTop={2}
                marginBottom={2}
            >
                <Grid item width={300}>
                    <Autocomplete
                        fullWidth={true}
                        width={300}
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        value={autoCompleteValue}
                        onChange={(event, newvalue) => { handleAutoCompleteChange(newvalue) }}
                        // onClose={handleAutoCompleteChange()}
                        options={missionsList.map((option) => option)}
                        getOptionLabel={(option => option.title)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                width={100}
                                {...params}
                                label="Chercher une mission"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item width={300} marginTop={5}>
                    <Autocomplete
                        fullWidth={true}
                        width={300}
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        value={autoCompletePrestaValue}
                        onChange={(event, newvalue) => { handleAutoCompletePrestaChange(newvalue) }}
                        options={studentsList.map((option) => option)}
                        getOptionLabel={(option => option.title)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                width={100}
                                {...params}
                                label="Chercher un presta"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch checked={switchDate} onChange={(e) => handleSwitchChange(e.target.checked)}>
                                Filtrer par date
                            </Switch>
                        } label="Choisir des dates" />
                    </FormGroup>
                </Grid>
                <Box hidden={switchDate === false}>
                    <Grow in={switchDate} >
                        <Grid item marginTop={1}>
                            <Grid container justifyContent="space-between" alignItems="default" >
                                <Grid item marginRight={1}>
                                    <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} />}
                                            name="begin_datetime"
                                            label="A partir de:"
                                            value={ExportForm.begin_datetime}
                                            onChange={(date) => handleDatetimePickerChange(date, "begin_datetime")}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item marginLeft={1}>
                                    <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} />}
                                            name="end_datetime"
                                            label="Jusqu'à:"
                                            value={ExportForm.end_datetime}
                                            onChange={(date) => handleDatetimePickerChange(date, "end_datetime")}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grow>
                </Box>
                {/* <Grid item>
                            <Button onClick={(e) => getMission()}>Valider ma séléction<AddTaskIcon /></Button>
                        </Grid> */}
                <Grid item marginTop={2}>
                    <ExportCSV ExportForm={ExportForm} />
                </Grid>
                {/* <CSVLink data={csvData}>Download me</CSVLink> */}
                {/* <CSVLink data={workingSessions} headers={headers} separator={","}>Download working sessions</CSVLink> */}
                {/* <CSVDownload data={csvData} target="_blank" /> */}
            </Grid>
        </Box>
    )
}

export default AdminMissionExport;