import { AppConfig } from "./AppConfig";

async function verifyTokenAPI(token) {
    const response = await fetch(AppConfig.api_ip + "/token/verify/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(token),
    });
    return await response.status;
}

export async function verifyToken(token) {
    if (token == undefined) {
        return false;
    }

    const response = await verifyTokenAPI({ token: token });

    if (response == 200) {
        return true;
    }
    return false;
}

export async function getUserInfo(token) {
    const response = await fetch(AppConfig.api_ip + "/identity/whoami/", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    });

    return await response.json()
}

export async function getIsAdmin(token) {
    if  (token == undefined) {
        return false;
    }
    const response = await fetch(AppConfig.api_ip + "/identity/amiadmin/", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    });

    return await response.ok;
}

export async function postRequestAPI(endpoint, token, data) {
    const response = await fetch(AppConfig.api_ip + endpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(data),
    });
    return await response;
}

export async function patchRequestAPI(endpoint, token, data) {
    const response = await fetch(AppConfig.api_ip + endpoint, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(data),
    });
    return await response;
}

export async function getRequestAPI(endpoint, token) {
    const response = await fetch(AppConfig.api_ip + endpoint, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    });
    return await response;
}

export async function deleteRequestAPI(endpoint, token) {
    const response = await fetch(AppConfig.api_ip + endpoint, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    });
    return await response;
}

export function formatDatetime(datetime) {
    const formatted = new Date(datetime)
    return formatted.toLocaleString();
}

export function getCRIPhotoUrl(last_name, first_name)
{
    return "https://photos.cri.epita.fr/" + first_name.toLowerCase() + "." + last_name.toLowerCase();
}