const fr_fr = {
    button: {
        agree: "Confirmer",
        add: "Ajouter",
        auto: "Auto,",
        cancel: "Annuler",
        back: "Retour"
    }
}

/*
    * This var contains all the configuration of the application
*/
export const AppConfig = {
    api_ip: process.env.REACT_APP_API_URL || "https://intranet.cristal.je/v1",
    lang: fr_fr,
}

export const UpdateWorkingSessionFormConfig = {
    title: "Modifier une session de travail",
    description: "Vous pouvez modifier une session de travail",
    success: "La session de travail a été modifiée",
}

export const AddWorkingSessionFormConfig = {
    title: "Ajouter une session de travail",
    description: "Pour ajouter une session de travail vous devez préciser une titre, une description ainsi que vos horaires.",
    success: "La session de travail a été ajoutée",
}