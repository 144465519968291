import React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from "@mui/material/Grid";
import { useCookies } from "react-cookie";
import { Alert } from "@mui/material";
import { postRequestAPI, patchRequestAPI, getRequestAPI } from "../utils";
import { Stack } from "@mui/material";
import ErrorMessage from "./ErrorMessage";
import { fr } from 'date-fns/locale';
import { AppConfig } from "../AppConfig";
import MessageSnackBar from "./MessageSnackBar";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@material-ui/lab";

// Limit the number of options displayed in the autocomplete
const OPTIONS_LIMIT = 3;
const filterOptions = createFilterOptions({
  limit: OPTIONS_LIMIT
});

/*
  * This component is a modal to add or edit a working session on admin side
  * It is used in AdminMissionDetail.js
  * It is a modal that opens when the user clicks on the "Modifier" button.
*/
function AdminWorkingSessionFormModal(props) {
  const [cookies] = useCookies();
  // a boolean telling if there was an error when the form has been send 
  const [hasError, setHasError] = React.useState(false);
  // the error message of the form
  const [errorMessage, setErrorMessage] = React.useState({});
  // the snack bar state
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  // The working session manipulated by the component
  const [workingSessionForm, setWorkingSessionForm] = React.useState(props.workingSession);
  // Worker Array for select map
  const [arrayWorkers, setArrayWorkers] = React.useState([]);
  // Boolean saying if user autocomplete is open or not
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [autocompleteValue, setAutoCompleteValue] = React.useState("");

  const [allWorkers, setAllWorkers] = React.useState([]);

  // When the component is updated, the error message need to be reseted
  React.useEffect(() => {
    if (Object.keys(errorMessage).length > 0) {
      setHasError();
    }
    else {
      setHasError(false);
    }    
    getWorkers();
    getAllWorkers();
  }, [props.workers])
  
  // Function to close modal and reset form error message
  function handleClose() {
    props.setIsModalOpen(false);
    setErrorMessage({})
  }

  // Function to handle text field change
  function handleTextFieldChange(event) {
    // Retrieve the input text
    const value = event.target.value;

    // Set the working session accordingly
    setWorkingSessionForm({
      ...workingSessionForm,
      [event.target.name]: value
    })
  }

  function handleWorkerFieldChange(event, worker) {
    var id = null;
    allWorkers.forEach(element => { 
      if(element.email === worker){
      id = element.id;
    }
    });
    // Set the working session accordingly
    if(id != null){
      setWorkingSessionForm({
      ...workingSessionForm,
      ["owner"]: id
    })
  }
  }

  function handleStateCheckboxFieldChange(event) {
    // Retrieve the checkbox value
    var value = 2;
    if (event.target.checked == false)
      value = 0
    // Set the working session accordingly
    setWorkingSessionForm({
      ...workingSessionForm,
      ["state"]: value
    })
  }

  // Function to handle dateime picker changes 
  function handleDatetimePickerChange(date, field) {

    // Set the working session accordingly
    setWorkingSessionForm({
      ...workingSessionForm,
      [field]: date.toJSON(),
    })
  }

  async function dispatch(data) {
    switch (props.type) {
      case "update":
        return updateWorkingSession(data);
      default:
        return postWorkingSession(data);
    }
  }

  // function to send the working session to update
  async function postWorkingSession(data) {
    const response = await postRequestAPI("/manage/working-sessions/", cookies.auth_token, data);

    if (response.ok) {
      return true;
    }

    const body = await response.json();

    setHasError(true);
    setErrorMessage(body);

    return false;
  }

  async function getAllWorkers()
  {
    const studentsFromApi = await getRequestAPI("/students/", cookies.auth_token);
    const body = await studentsFromApi.json();
    setAllWorkers(body);
  }
  
  async function getWorkers()
  {
      const response = await getRequestAPI("/students/", cookies.auth_token)
      const body = await response.json();

      setArrayWorkers(body)
  }

  // function to send the working session to create
  async function updateWorkingSession(data) {
    const response = await patchRequestAPI("/manage/working-sessions/" + props.workingSession.id + "/", cookies.auth_token, data);

    if (response.ok) {
      return true;
    }

    const body = await response.json();

    setHasError(true);
    setErrorMessage(body);

    return false;
  }

  // Function called when the submit button has been activated
  async function handleSubmit(event) {
    setErrorMessage({});
    setHasError(false);

    const response = await dispatch(workingSessionForm);
 
    if (response === true) {
      props.setIsModalOpen(false);

      setSnackBarOpen(true);

      setTimeout(() => window.location.reload(1), 2000);
    }

  }
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' }};
  return (
    <div>
      <MessageSnackBar open={snackBarOpen} toogleSnackBar={setSnackBarOpen} message={props.config.success} />
      <Dialog open={props.isModalOpen} onClose={handleClose}>
        <DialogTitle>{props.config.title}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              {props.config.description}
            </DialogContentText>
            {hasError === true ?
              <Alert severity="warning">
                <ErrorMessage data={errorMessage} />
              </Alert> : <div></div>}
            <Autocomplete
              clearOnEscape
              freeSolo
              id="auto-workers"
              open={openAutocomplete}
              onOpen={() => {
                if (autocompleteValue)
                  setOpenAutocomplete(true)
              }}
              onClose={() => setOpenAutocomplete(false)}
              inputValue={autocompleteValue}
              onInputChange={(event, value, reason) => {
                setAutoCompleteValue(value);
                  
                if (!value)
                  setOpenAutocomplete(false)
              }}
              filterOptions={filterOptions}
              options={arrayWorkers.map((worker) => worker.email)}
              
              onChange={(event, newValue) => handleWorkerFieldChange(event, newValue)}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Prestataires"/>}
            />
            <TextField
              id="name"
              name="title"
              label="Titre de la session"
              value={workingSessionForm.title}
              type="text"
              fullWidth
              onChange={handleTextFieldChange}
            />
            <TextField
              id="name"
              name="description"
              label="Description de la session"
              value={workingSessionForm.description}
              type="text"
              minRows={3}
              fullWidth
              onChange={handleTextFieldChange}
            />
            <FormControlLabel
            control={
            <Checkbox
              name="state"
              checked={(workingSessionForm.state == 2)? true : false}
              onChange={handleStateCheckboxFieldChange}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />} label={(workingSessionForm.state == 2)? "Je valide la session de travail" : "Je ne valide pas la session de travail"}
            />
            <Grid container justifyContent="space-between" alignItems="default">
              <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  name="begin_datetime"
                  label="Début de la session"
                  value={workingSessionForm.begin_datetime}
                  onChange={(date) => handleDatetimePickerChange(date, "begin_datetime")}
                  fullWidth
                />
              </LocalizationProvider>
              <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  name="end_datetime"
                  label="Fin de la session"
                  value={workingSessionForm.end_datetime}
                  onChange={(date) => handleDatetimePickerChange(date, "end_datetime")}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{AppConfig.lang.button.back}</Button>
          <Button onClick={handleSubmit}>{AppConfig.lang.button.agree}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AdminWorkingSessionFormModal;