import React from "react";

/*
    * This component is use to display an error message
    * It takes an array of errors as props and display the first one
*/
function ErrorMessage(props) {
    const keys = Object.keys(props.data);
    if (keys.length > 0) {
        const key = Object.keys(props.data)[0];
        const value = props.data[key];
        return (<div>
            <b>{key[0].toUpperCase() + key.slice(1)}</b>: {value}
        </div>)
    }
    return (<div></div>)
}

export default ErrorMessage;