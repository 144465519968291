import React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from "@mui/material/Grid";
import { useCookies } from "react-cookie";
import { Alert } from "@mui/material";
import { postRequestAPI, patchRequestAPI } from "../utils";
import { Stack } from "@mui/material";
import ErrorMessage from "./ErrorMessage";
import { fr } from 'date-fns/locale';
import { AppConfig } from "../AppConfig";
import MessageSnackBar from "./MessageSnackBar";

/*
  * This component is a modal to edit a working session on user side
  * It is used in MissionDetail.js
  * It is a modal that opens when the user clicks on the "Modifier" button.
*/
function WorkingSessionFormModal(props) {
  const [cookies] = useCookies();
  // a boolean telling if there was an error when the form has been send 
  const [hasError, setHasError] = React.useState(false);
  // the error message of the form
  const [errorMessage, setErrorMessage] = React.useState({});
  // the snack bar state
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  // The working session manipulated by the component
  const [workingSessionForm, setWorkingSessionForm] = React.useState(props.workingSession);

  // When the component is updated, the error message need to be reseted
  React.useEffect(() => {
    if (Object.keys(errorMessage).length > 0) {
      setHasError();
    }
    else {
      setHasError(false);
    }
  }, [])

  // Function to close modal and reset form error message
  function handleClose() {
    props.setIsModalOpen(false);
    setErrorMessage({})
  }

  // Function to handle text field change
  function handleTextFieldChange(event) {
    // Retrieve the input text
    const value = event.target.value;

    // Set the working session accordingly
    setWorkingSessionForm({
      ...workingSessionForm,
      [event.target.name]: value
    })
  }

  // Function to handle dateime picker changes 
  function handleDatetimePickerChange(date, field) {

    // Set the working session accordingly
    setWorkingSessionForm({
      ...workingSessionForm,
      [field]: date.toJSON(),
    })
  }

  async function dispatch(data) {
    switch (props.type) {
      case "update":
        return updateWorkingSession(data);
      default:
        return postWorkingSession(data);
    }
  }

  // function to send the working session to update
  async function postWorkingSession(data) {
    const response = await postRequestAPI("/working-sessions/", cookies.auth_token, data);

    if (response.ok) {
      return true;
    }

    const body = await response.json();

    setHasError(true);
    setErrorMessage(body);

    return false;
  }

  // function to send the working session to create
  async function updateWorkingSession(data) {
    const response = await patchRequestAPI("/working-sessions/" + props.workingSession.id + "/", cookies.auth_token, data);

    if (response.ok) {
      return true;
    }

    const body = await response.json();

    setHasError(true);
    setErrorMessage(body);

    return false;
  }

  // Function called when the submit button has been activated
  async function handleSubmit(event) {

    setErrorMessage({});
    setHasError(false);

    const response = await dispatch(workingSessionForm);

    if (response === true) {
      props.setIsModalOpen(false);

      setSnackBarOpen(true);

      setTimeout(() => window.location.reload(1), 2000);
    }

  }

  return (
    <div>
      <MessageSnackBar open={snackBarOpen} toogleSnackBar={setSnackBarOpen} message={props.config.success} />
      <Dialog open={props.isModalOpen} onClose={handleClose}>
        <DialogTitle>{props.config.title}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              {props.config.description}
            </DialogContentText>
            {hasError === true ?
              <Alert severity="warning">
                <ErrorMessage data={errorMessage} />
              </Alert> : <div></div>}
            <TextField
              id="name"
              name="title"
              label="Titre de la session"
              value={workingSessionForm.title}
              type="text"
              fullWidth
              onChange={handleTextFieldChange}
            />
            <TextField
              id="name"
              name="description"
              label="Description de la session"
              value={workingSessionForm.description}
              type="text"
              minRows={3}
              fullWidth
              onChange={handleTextFieldChange}
            />
            <Grid container justifyContent="space-between" alignItems="default">
              <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  name="begin_datetime"
                  label="Début de la session"
                  value={workingSessionForm.begin_datetime}
                  onChange={(date) => handleDatetimePickerChange(date, "begin_datetime")}
                  fullWidth
                />
              </LocalizationProvider>
              <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  name="end_datetime"
                  label="Fin de la session"
                  value={workingSessionForm.end_datetime}
                  onChange={(date) => handleDatetimePickerChange(date, "end_datetime")}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{AppConfig.lang.button.back}</Button>
          <Button onClick={handleSubmit}>{AppConfig.lang.button.agree}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default WorkingSessionFormModal;