import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CardActions from "@mui/material/CardActions"; 
import { useNavigate } from 'react-router-dom';
import { Container } from "@mui/system";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { AvatarGroup, Avatar, Tooltip } from "@mui/material";
import { Stack } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getCRIPhotoUrl } from "../utils";

/*
    * This component is used to display a mission card on user side
*/
function MissionCard(props) {
    const navigate = useNavigate();

    if (props.data === undefined)
        return (<div></div>)

    return (
        <Card sx={{ minWidth: 150, backgroundImage: "url(https://sandra.pascal-geay.fr/wp-content/uploads/2019/03/work.jpeg)", backgroundSize: "cover", backgroundPosition: "center" }} color="text.secondary" key={props.data.mission.id} >
            <Container sx={{ background: "rgba(0,0,0,0.4)", color: "white" }}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={1}>
                        <CardContent key={props.data.mission.id + " content"}>
                            {props.data.mission.closed ? <LockIcon color="error" fontSize="large" /> : <LockOpenIcon fontSize="large" color="success" />}
                        </CardContent>
                    </Grid>
                    <Grid item xs={(props.see_details === true) ? 9 : 11}>
                        <CardContent key={props.data.mission.id + " content"}>
                            <Stack spacing={1}>
                                <Typography variant="h6" key={props.data.mission.id + " title"} sx={{ fontWeight: "bold", textTransform: 'uppercase' }}>
                                    {props.data.mission.title}
                                </Typography>
                                <Typography style={{
                                    display: 'flex',
                                    alignItems: 'stretch',
                                }}>
                                    <Tooltip title="Vos dates de travail sur cette mission"><AccessTimeIcon sx={{ marginRight: '10px' }} /></Tooltip>
                                    du {props.data.start_date} au {props.data.end_date}
                                </Typography>
                                <Typography key={props.data.mission.id + " description"}>
                                    {props.data.mission.description}
                                </Typography>
                                <div>
                                    <AvatarGroup max={5} total={props.data.mission.owners.length} sx={{ justifyContent: 'left' }}>
                                        {props.data.mission.owners.map((owner) => (
                                            <Tooltip title={owner.last_name + " " + owner.first_name} key={owner.id}>
                                                <Avatar alt={owner.last_name + " " + owner.first_name} src={getCRIPhotoUrl(owner.last_name, owner.first_name)} />
                                            </Tooltip>
                                        ))}
                                    </AvatarGroup>
                                </div>
                            </Stack>
                        </CardContent>
                    </Grid>
                    {(props.see_details == true) ?
                        <Grid item xs={2}>
                            <CardActions key={props.data.mission.id + " action"}>
                                <Button variant="contained" className="form__custom-button" onClick=
                                    {(e) => navigate("/missions/" + props.data.mission.id)}
                                >Voir</Button>
                            </CardActions>
                        </Grid> :
                        <div></div>
                    }
                </Grid>
            </Container>
        </Card >
    )
}

export default MissionCard