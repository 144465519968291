import * as React from 'react';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import WorkingSessionFormModal from './WorkingSessionFormModal';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import { deleteRequestAPI, postRequestAPI } from '../utils';
import { useCookies } from 'react-cookie';
import ConfirmationDialog from './ConfirmationDialog';
import { UpdateWorkingSessionFormConfig } from '../AppConfig';

/*
    * This component is used to display a button of options for a working session on user side.
    * It is used in the WorkingSessionList component and appears at the end of the table.
    * REMINDER : state 0 = draft, 1 = submitted, 2 = validated, 3 = refused
*/
export default function WorkingSessionOption(props) {
    const [cookies] = useCookies();
    const [modalOpen, setModalOpen] = React.useState(false);

    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const [confirmationCallback, setConfirmationCallback] = React.useState(undefined);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [snackOpen, setSnackOpen] = React.useState(false);
    const [actionType, setActionType] = React.useState("");

    const successMessage = {
        update: "La session de travail a été modifiée.",
        delete: "La session de travail a été supprimer",
        submit: "La session de travail a été soumise pour validation",
    };

    function handleSnackClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleUpdate(event) {
        handleClose();
        setActionType("update")
        setModalOpen(true);
    }

    function handleSubmit(event) {
        handleClose();
        setActionType("submit");
        validateWorkingSession();
    }

    async function validateWorkingSession() {
        const response = await postRequestAPI("/working-sessions/" + props.workingSession.id + "/state/", cookies.auth_token, { state: 1 });

        if (response.ok) {
            setSnackOpen(true)
            return true;
        }

        return false;
    }

    async function deleteWorkingSession() {
        const response = await deleteRequestAPI("/working-sessions/" + props.workingSession.id + "/", cookies.auth_token);

        if (response.ok) {
            setSnackOpen(true);
            setTimeout(() => window.location.reload(1), 2000);
        }
    }

    function handleDelete(event) {
        handleClose();
        setActionType("delete");
        deleteWorkingSession();
    }

    function handleMore(event) {
        handleClose();
        props.detailsSet(!props.detailsOpen)
    }

    function dispatch(action) {
        switch(action) {
            case "update":
                handleUpdate()
                break;
            case "delete":
                setConfirmationCallback(() => handleDelete)
                setConfirmationModalOpen(true);
                break;
            case "submit":
                setConfirmationCallback(() => handleSubmit)
                setConfirmationModalOpen(true);
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {successMessage[actionType]}
                </Alert>
            </Snackbar>
            <ConfirmationDialog dialogOpen={confirmationModalOpen} toogleDialog={setConfirmationModalOpen} callback={confirmationCallback} description={"Êtes-vous sûr de vouloir réaliser cette action?"} title={"Confirmation"} />
            <Button
                variant="outlined"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
            >
                Actions
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleMore} selected={props.detailsOpen} disableRipple>
                    <ListItemIcon>
                        <MoreHorizIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Voir plus</Typography>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem disabled={(props.workingSession.state !== 0 && props.workingSession.state !== 3)} onClick={() => dispatch("submit")} selected={props.detailsOpen} disableRipple>
                    <ListItemIcon>
                        <PublishIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Soumettre</Typography>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem disabled={props.workingSession.state !== 0 && props.workingSession.state !== 3} onClick={() => dispatch("update")} disableRipple>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Modifier</Typography>
                </MenuItem>
                <MenuItem disabled={props.workingSession.state !== 0} onClick={() => dispatch("delete")} disableRipple>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Supprimer</Typography>
                </MenuItem>
            </Menu>
            <WorkingSessionFormModal config={UpdateWorkingSessionFormConfig} type="update" workingSession={props.workingSession} toogleSnack={setSnackOpen} isModalOpen={modalOpen} setIsModalOpen={setModalOpen} />
        </div>
    );
}