import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { useCookies } from "react-cookie";
import React, { useState } from "react";
import CristalAppBar from './components/AppBar';
import MissionPage from './pages/MissionPage';
import MissionDetail from './pages/MissionDetail';
import { UserContext } from './contexts/user';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import { getUserInfo, getIsAdmin, postRequestAPI } from './utils';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import ErrorMessage from './components/ErrorMessage';
import { Alert } from '@mui/material';
import AdminMissionDetail from './pages/AdminMissionDetail';
import AdminMissionPage from './pages/AdminMissionPage';
import AdminMissionExport from './pages/AdminMissionExport';


function Layout() {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  const [error, setError] = React.useState({});
  const [hasError, setHasError] = React.useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { user, setUser } = React.useContext(UserContext);
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    if (Object.keys(error).length > 0) {
      setHasError();
    }
    else {
      setHasError(false);
    }
    async function relaodAdmin() {
      const admin = await getIsAdmin(cookies.auth_token);
      setIsAdmin(admin);
    }

    relaodAdmin();
  }, [])

  const handleUsernameChange = e => {
    setUsername(e.currentTarget.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.currentTarget.value);
  };

  async function getToken(email, password) {
    const response = await postRequestAPI("/token/", "", { email: username, password: password });

    if (response.ok) {
      const token = await response.json()

      setCookie("auth_token", token.access, {
        path: "/"
      });

      return true;
    }

    const body = await response.json();

    setHasError(true);
    setError(body);

    return false;
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setError({});
    setHasError(false);

    try {

      const response = await getToken(username, password);

      if (response === true) {
        window.location.reload(1);
      }

    }
    catch (error) {
      setHasError(true);
      setError(error);
    }
  };

  if (user.id == undefined) {
    return (

      <div>
        <Box sx={{ width: "400px;", margin: "auto", marginTop: "10%" }}>
          <Stack spacing={2}>
            <img src="https://www.cristal.je/wp-content/uploads/2021/03/cristal-logo-vect.svg"></img>
            {hasError === true &&
              <Alert severity="warning">
                <ErrorMessage data={error} />
              </Alert>}
            <TextField
              id="username"
              type="text"
              label="Adresse mail"
              onChange={handleUsernameChange}
              fullWidth
            />
            <TextField
              id="password"
              type="password"
              label="Mot de passe"
              onChange={handlePasswordChange}
              fullWidth
            />
            <Button type="button" variant="contained" size="large" color="success" fullWidth onClick={handleSubmit}>
              Log in
            </Button>
          </Stack>
        </Box>
      </div>
    )
  }
  return (
    <div>
      <Router>
        <CristalAppBar admin={false} />
        <Routes>
          <Route path='/' element={<MissionPage />} />
          <Route path='/missions' element={<MissionPage />} />
          <Route
            path={`/missions/:missionId`}
            element={<MissionDetail />}
          />
          <Route path='/admin/missions' exact element={<AdminMissionPage />} />
          <Route path='/admin/missions/:missionId' element={<AdminMissionDetail />} />
          <Route path='/export' element={<AdminMissionExport/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default Layout;
